// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loan-tsx": () => import("./../../../src/pages/loan/[...].tsx" /* webpackChunkName: "component---src-pages-loan-tsx" */),
  "component---src-pages-novel-tsx": () => import("./../../../src/pages/novel/[...].tsx" /* webpackChunkName: "component---src-pages-novel-tsx" */),
  "component---src-pages-tools-base-64-tsx": () => import("./../../../src/pages/tools/base64.tsx" /* webpackChunkName: "component---src-pages-tools-base-64-tsx" */),
  "component---src-pages-tools-google-translate-tsx": () => import("./../../../src/pages/tools/google-translate.tsx" /* webpackChunkName: "component---src-pages-tools-google-translate-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-tools-urlencode-tsx": () => import("./../../../src/pages/tools/urlencode.tsx" /* webpackChunkName: "component---src-pages-tools-urlencode-tsx" */),
  "component---src-pages-video-tsx": () => import("./../../../src/pages/video/[...].tsx" /* webpackChunkName: "component---src-pages-video-tsx" */),
  "component---src-pages-wedding-song-tsx": () => import("./../../../src/pages/wedding/song.tsx" /* webpackChunkName: "component---src-pages-wedding-song-tsx" */)
}

